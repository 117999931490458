import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import LayoutWrapper from "../Layout";
import ContactForm from "../Components/contact-form";
import { Link } from "react-router-dom";

function Contact() {
  return (
    <div>
  <div className="page-heading header-text">
    <Container>
      <Row>
        <Col md={12}>
          <h1>Contact Us</h1>
          <span>
            Please feel free to send us a message now!
          </span>
        </Col>
      </Row>
    </Container>
  </div>
  <div className="contact-information">
    <Container>
      <Row>
        <Col md={12}>
          <div className="section-heading">
            <h2>
              Our <em>Cameroon</em> Offices
            </h2>
            <span>Reach Out To Us</span>
          </div>
        </Col>
        <Col md={4}>
          <div className="contact-item">
            <h4>Douala</h4>
            <p>Rue 1235, Batibios No 756 C, Off Road NjoNjo, by Hotel La Falaise, Bonapriso</p>
            <Link to="tel:(+237)6-99-85-63-74"><i className="fa fa-phone"></i>(+237)6 99 85 63 74</Link>
            <br/>
            <Link to="mailto:info@asahalpha.com"><i className="fa fa-envelope"></i>info@asahalpha.com</Link>
          </div>
        </Col>
        <Col md={4}>
          <div className="contact-item">
            <h4>Yaounde</h4>
            <p>Contact Our Manager: Roland Adambah Ngwana</p>
            <Link to="tel:(+237)6-99-85-63-74"><i className="fa fa-phone"></i>(+237)6 99 85 63 74</Link>
            <br/>
            <Link to="mailto:info@asahalpha.com"><i className="fa fa-envelope"></i>info@asahalpha.com</Link>
          </div>
        </Col>
        <Col md={4}>
          <div className="contact-item">
            <h4>Bamenda</h4>
            <p>Commercial Avenue, Aboboh Building, Room 3</p>
            <Link to="tel:(+237)6-99-85-63-74"><i className="fa fa-phone"></i>(+237)6 99 85 63 74</Link>
            <br/>
            <Link to="mailto:info@asahalpha.com"><i className="fa fa-envelope"></i>info@asahalpha.com</Link>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
  <div className="contact-information">
  <Container>
      <Row>
        <Col md={12}>
          <div className="section-heading">
            <h2>
              Our <em>Nigeria</em> Offices
            </h2>
            <span>Reach Out To Us</span>
          </div>
        </Col>
        <Col md={4}>
          <div className="contact-item">
            <h4>Abuja</h4>
            <p>Suite 17, Vickbalkon Towers, 92 Bimbo Akintola, Utako</p>
            <Link to="tel:(+234)802-510-4668"><i className="fa fa-phone"></i>(+234)802 510 4668</Link>
            <br/>
            <Link to="mailto:info@asahalpha.com"><i className="fa fa-envelope"></i>info@asahalpha.com</Link>
          </div>
        </Col>
        <Col md={4}>
          <div className="contact-item">
            <h4>Lagos</h4>
            <p>50 Adeneyi Jones Ave, Ikeja</p>
            <Link to="tel:(+234)802-510-4668"><i className="fa fa-phone"></i>(+234)802 510 4668</Link>
            <br/>
            <Link to="mailto:info@asahalpha.com"><i className="fa fa-envelope"></i>info@asahalpha.com</Link>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
  <div className="callback-form">
    <Container>
      <Row>
        <Col md={12}>
          <div className="section-heading">
            <h2>
              Send us a <em>message</em>
            </h2>
            <span>We are ready to assist you.</span>
          </div>
        </Col>
        <Col md={12}>
          <ContactForm />
        </Col>
      </Row>
    </Container>
  </div>
  <div>
    <br />
    <br />
  </div>
  <div>
    <br />
    <br />
  </div>
  <div>
    <br />
    <br />
  </div>
</div>

  );
}

export default LayoutWrapper(Contact);
