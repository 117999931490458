import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';

function Header() {
  return (
    <header>
  <div className="sub-header">
    <div className="container">
      <div className="row">
        <div className="">
          <ul className="left-info">
            <li>
              <a>
                <i className="fa fa-clock-o" style={{fontSize:"12px",color:"white"}}></i>Monday - Saturday 08:00-17:00
              </a>
            </li>
            <li>
              <a>
                <i className="fa fa-phone" style={{fontSize:"12px",color:"white"}}></i>+237 243 11 60 80
              </a>
              
            </li>
            <li>
              
              <a target="_blank" href="mailto:info@asahalpha.com" title="Mail Us">
                <i className="fa fa-envelope" style={{fontSize:"12px",color:"white"}}></i>info@asahalpha.com
              </a>
            </li>
            <li className="icons">
              <a target="_blank" href="" title="Linkedin">
                <i className="fa fa-linkedin" style={{fontSize:"12px",color:"white"}}></i>
              </a>
              <a target="_blank" href="https://twitter.com/asahalpha_info" title="Twitter">
                <i className="fa fa-twitter" style={{fontSize:"12px",color:"white"}}></i>
              </a>
              <a target="_blank" href="" title="Facebook">
                <i className="fa fa-facebook" style={{fontSize:"12px",color:"white"}}></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <Navbar className="navbar navbar-expand-lg" collapseOnSelect expand="lg">
    <Container>
      <Navbar.Brand as={Link} to="/">
        <img src="asah.jpeg" alt="Asah Alpha & Co" style={{color:"white"}}></img>
      </Navbar.Brand>
      <Navbar.Toggle
        className="navbar-toggler"
        aria-controls="navbarResponsive"
        aria-expanded="false"
        aria-label="Toggle navigation"
      />
      <Navbar.Collapse id="navbarResponsive">
        <Nav className="navbar-nav ml-auto">
          <Nav.Link as={NavLink} to="/" className="nav-link">
            Home
          </Nav.Link>
          <Nav.Link as={NavLink} to="/services" className="nav-link dropdown">
            Services
          </Nav.Link>
          <Nav.Link as={NavLink} to="/about" className="nav-link">
            About Us
          </Nav.Link>
          <Nav.Link as={NavLink} to="/contact" className="nav-link">
            Contact
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
</header>
  );
}

export default Header;

