import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

class HeaderSlider extends React.Component {
  render() {
    const settings = {
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true,
      arrows: false,
    };

    return (
      <div>
  <Slider className="Modern-Slider" {...settings}>
    <div className="item item-1">
      <div className="img-fill">
        <div className="text-content">
          <h6>We are ready to assist you</h6>
          <h4>With Your Enterprise</h4>
          <p>
          Asah Alpha & Co - African Global Firm of Certified Chartered Accountants.
          </p>
          <Link to="/contact" className="filled-button">
            Contact Us
          </Link>
        </div>
      </div>
    </div>
    <div>
      <div className="item item-2">
        <div className="img-fill">
          <div className="text-content">
            <h6>The Path to Financial Success</h6>
            <h4>
              Financial Advice <br />
              & Taxation
            </h4>
            <p>
            Asah Alpha & Co - African Global Firm of Certified Chartered Accountants.
            </p>
            <Link to="/services" className="filled-button">
              Our Services
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div className="item item-3">
        <div className="img-fill">
          <div className="text-content">
            <h6>Meet Our Experienced Team</h6>
            <h4>
              Of Chatered Accountants
            </h4>
            <p>
            Asah Alpha & Co - African Global Firm of Certified Chartered Accountants.
            </p>
            <Link to="/about" className="filled-button">
              About Us
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div className="item item-4">
        <div className="img-fill">
          <div className="text-content">
            <h6>Verified Expertise and Complete Trust</h6>
            <h4>Diverse Financial Expertise</h4>
            <p>
              Asah Alpha & Co - African Global Firm of Certified Chartered Accountants.
            </p>
            <Link to="/request" className="filled-button">
              Submit RFP
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div className="item item-5">
        <div className="img-fill">
          <div className="text-content">
            <h6>Coaching and Training Expertise</h6>
            <h4>With Specialised Expertise</h4>
            <p>
              Asah Alpha & Co - African Global Firm of Certified Chartered Accountants.
            </p>
            <Link to="/request" className="filled-button">
              Submit RFT
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Slider>
</div>
    );
  }
}

export default HeaderSlider;
