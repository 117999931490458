import React, { useState } from "react";
import LayoutWrapper from "../Layout";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import ContactForm from "../Components/contact-form";

function Services() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div>
  <div className="page-heading header-text">
    <Container>
      <div className="row">
        <div className="col-md-12">
          <h1>Our Services</h1>
          <span>We have over 10 years of experience.</span>
        </div>
      </div>
    </Container>
  </div>
  <div className="single-services">
    <Container>
      <Row id="tabs">
        <Col md={12}>
          <div className="section-heading">
            <h2>
              Our <em>Core</em> Services
            </h2>
            <span>An Extensive Breakdown</span>
          </div>
        </Col>
        <div className="col-md-4">
          <div className="tab-buttons">
            <ul>
              <li>
                <Link
                  to="#tabs-1"
                  className={`tab-button ${
                    activeTab === 0 ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(0)}
                >
                  Auditing and assurance
                </Link>
              </li>
              <li>
                <Link
                  to="#tabs-2"
                  className={`tab-button ${
                    activeTab === 1 ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(1)}
                >
                  Accountancy
                </Link>
              </li>
              <li>
                <Link
                  to="#tabs-3"
                  className={`tab-button ${
                    activeTab === 2 ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(2)}
                >
                  Taxation
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-8">
          <section className="tabs-content">
            {activeTab === 0 && (
              <article id="tabs-1">
                <img src="images/single_service_01.jpg" alt="" />
                <h4>Auditing And Assurance</h4>
                <p>
                Our Audit and Assurance Services Division is strategically structured to respond to the diverse growing markets needs in the sphere we occupy and beyond. 
                  <br />
                  We believe in the concept of specializations for optimum quality in our services. Audit teams equipped with a high level of technical skills and empowered with professional skepticism provide the heart and soul of a good audit.

                  <br />
                  Tailored to the particular needs of the clients, Asah Alpha & Co:
                  <br />
                  1.	Utilizes a multidisciplinary approach with industry-experienced and tested teams with the knowledge, skills, and experience acquired to perform the engagement. 
                  <br />
                  2.	Emphasizes the importance of substantive tests and procedures and effective risk assessment and control testing activities for audit evidences and trails. 
                  <br />
                  3.	Seek to meet all applicable national and international requirements, wherever we do business. 
                  <br />
                  4.	Provides a basis for a consistent audit approach.
                  <br />
                  It is worth saying that audit quality is vital for maintaining trust in the financial reporting process and to uphold the integrity of financial information, but also very true as a performance indicator and serves as the undisputable arbitrator to stakeholders in any organization and therefore not just a mere statutory fulfilment.
                  <br />
                </p>
              </article>
            )}
            {activeTab === 1 && (
              <article id="tabs-2">
                <img src="images/single_service_02.jpg" alt="" />
                <h4>Accountancy</h4>
                <p>
                  Asah Alpha & Co, Certified Chartered Accountants, is the leading African Firms that cuts across markets practicing different accounting systems with no linguistic, cultural or professional barriers.

                  <br />
                  The Firm has a mastering of the various accounting systems practiced in Central and West Africa including Nigeria/Ghana and the Republic Democratic Republic of Congo both in the Public and Private Sectors.

                  <br />
                  Our mastery of the complexities of financial transaction in a constant evolving business environment is the “raison d'être” we are the trusted Accountants for big corporations in the Private Sector, Governments, Diplomatic Missions and Donors Funded.
                </p>
              </article>
            )}
            {activeTab === 2 && (
              <article id="tabs-3">
                <img src="images/single_service_03.jpg" alt="" />
                <h4>Taxation</h4>
                <p>
                Taxation is integral part of business life at the point of inception and operation through the life circle of any particular business organisation. 
                  <br />
                  The African Region cutting across many tax jurisdiction is of particular is an issue of great importance when investment decision come into play. 
                  <br /> 
                  Existing corporate organisations and business establishment should be mindful of expertise of their tax advisor as taxation is fundamental management factors that need rigorous planning and surveillance on continuous basis to allow no room for setback for corporate growth.

                  <br />
                  Due to the rapid change in legislation/regulation, and the digitalization of revenue authorities, getting it right is increasingly challenging and that where aac comes in with a wide range of tax services from tax filing to tax planning. 
                </p>
              </article>
            )}
            <Link to="/request" style={{marginTop:"30px"}} className="filled-button">
              Submit RFP
            </Link>
          </section>
        </div>
      </Row>
    </Container>
  </div>
  <div className="single-services">
    <Container>
      <Row id="tabs">
      <Col md={12}>
          <div className="section-heading">
            <h2>
              Our <em>Support</em> Services
            </h2>
            <span>An Extensive Breakdown</span>
          </div>
        </Col>
        <div className="col-md-8">
          <section className="tabs-right">
            {activeTab === 0 && (
              <article id="tabs-1">
                <img src="images/single_service_01.jpg" alt="" />
                <h4>IT Solutions</h4>
                <p>
                  Information Technology (IT) is the undisputable partners to business as they help in working faster and better than before. 
                  <br />
                  However, it can be complex and difficult to use without the right expertise. Asah Alpha & Co has taken the lead to acquire the required knowledge and to share this knowledge through their expertise in the following domains:

                  <br />
                  1. IT System Audit
                  <br />
                  2. Data analytics 
                  <br />
                  3. Blockchain Technology
                  <br />
                  4. Sage Services
                  <br />
                  5. TOMpro Services 
                  <br />
                  6.ERP (Enterprise Resource Planning) Services
                  <br />
                  7. AAC ITH (Information Technology Hardware) Shop
                  <br />
                </p>
              </article>
            )}
            {activeTab === 1 && (
              <article id="tabs-2">
                <img src="images/single_service_02.jpg" alt="" />
                <h4>Legal practices</h4>
                <p>
                  Our Legal Practices Consultants (lawyers) offer you first class specialist expertise in the domains we offer, delivered through personalized services tailored to your business needs.
                  <br />
                  We take the time to understand your structure, objectives, goals and approach to deals, so that we always provide you with advice that meets your unique needs, focuses on solving your problems and represents real value.

                  <br />
                </p>
              </article>
            )}
            {activeTab === 2 && (
              <article id="tabs-3">
                <img src="images/single_service_03.jpg" alt="" />
                <h4>Finance</h4>
                <p>
                  Absolute global reliable accounting information may carry very little risk, investment and RoI (returns on investment) indicators, thereby requiring further analysis and/or interpretation for accurate investment decisions. 
                  <br />
                  Asah Alpha & Co is the tested finance advisor in various aspects to many organizations within the Private and Public Sectors over the past 2 decades.
                  <br />
                </p>
              </article>
            )}
            {activeTab === 3 && (
              <article id="tabs-4">
                <img src="images/single_service_04.jpg" alt="" />
                <h4>Management practices</h4>
                <p>
                  Many large accounting firms offer business management services, but we go a step further. We work closely with each client, large or small, to provide the exact level of personalized service required. 
                  <br />
                  And our fee structure is flexible and individually tailored to each client's requirements. 
                  <br />
                  Our Management Consultancy team consist of business veterans with trial and proven background vast business experience and many years of practice. 
                  <br />
                  Asah Alpha & Co offers a wide range of specialized services in the domain of Management Practices.
                </p>
              </article>
            )}
            <Link to="/request" style={{marginTop:"30px"}} className="filled-button">
              Submit RFP
            </Link>
          </section>
        </div>
        <div className="col-md-4">
          <div className="tab-buttons">
            <ul>
              <li>
                <Link
                  to="#tabs-1"
                  className={`tab-button ${
                    activeTab === 0 ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(0)}
                >
                  IT Solutions
                </Link>
              </li>
              <li>
                <Link
                  to="#tabs-2"
                  className={`tab-button ${
                    activeTab === 1 ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(1)}
                >
                  Legal practices
                </Link>
              </li>
              <li>
                <Link
                  to="#tabs-3"
                  className={`tab-button ${
                    activeTab === 2 ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(2)}
                >
                  Finance
                </Link>
              </li>
              <li>
                <Link
                  to="#tabs-4"
                  className={`tab-button ${
                    activeTab === 3 ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(3)}
                >
                  Management practices
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Row>
    </Container>
  </div>
  <div className="single-services">
    <Container>
      <Row id="tabs">
      <Col md={12}>
          <div className="section-heading">
            <h2>
              Our <em>HR</em> Solution
            </h2>
            <span>An Extensive Breakdown</span>
          </div>
        </Col>
        <div className="col-md-4">
          <div className="tab-buttons">
            <ul>
              <li>
                <Link
                  to="#tabs-1"
                  className={`tab-button ${
                    activeTab === 0 ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(0)}
                >
                  Human resource solution
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-8">
          <section className="tabs-content">
            {activeTab === 0 && (
              <article id="tabs-1">
                <img src="images/single_service_01.jpg" alt="" />
                <h4>HR Solution</h4>
                <p>
                  Recycling and coaching to the public administration, corporations, international organisations, diplomatic missions’ workforce in specialised business disciplines constitutes the pivot solution offered by the HR Division of Asah Alpha & Co.
                  <br />
                  Our HR Solutions are standardised, tight and tougher than any found elsewhere in the world in order to guarantee our clients' human capital best performance.
                  <br />
                  Our training courses entail a combination of class work, exercises, real life experience, discussions, observations, homework, practical application and study. 
                  <br />
                  All our courses are intensive and they enable the participants to develop the expertise necessary to support an organisation in implementing skills acquired.
                  <br />
                  The HR Division is the organ responsible for all HR Solutions offered by Asah Alpha & Co.
                  <br />
                </p>
              </article>
            )}
            <Link to="/request" style={{marginTop:"30px"}} className="filled-button">
              Submit RFP
            </Link>
          </section>
        </div>
      </Row>
    </Container>
  </div>
  <div className="callback-form callback-services">
    <Container>
      <Row>
        <div className="col-md-12">
          <div className="section-heading">
            <h2>
              Contact us
              <em> now</em>
            </h2>
            <span>We are ready to assist you.</span>
          </div>
        </div>
        <div className="col-md-12">
          <ContactForm />
        </div>
      </Row>
    </Container>
  </div>
  <div>
    <br />
    <br />
  </div>
  <div>
    <br />
    <br />
  </div>
  <div>
    <br />
    <br />
  </div>
</div>

  );
}

export default LayoutWrapper(Services);
