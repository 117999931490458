import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import LayoutWrapper from "../Layout";

function About() {
  return (
    <div>
  <div className="page-heading header-text">
    <Container>
      <Row>
        <Col md={12}>
          <h1>About Us</h1>
          <span>We have over 10 years of experience.</span>
        </Col>
      </Row>
    </Container>
  </div>
  <div className="more-info about-info">
    <Container>
      <Row>
        <Col md={12}>
          <div className="more-info-content">
            <Row>
              <Col md={6} className="align-self-center">
                <div className="right-content">
                  <span>Solid expertise in the field of accounting.</span>
                  <h2>
                    Learn more about <em>our company</em>
                  </h2>
                  <p>
                    Asah Alpha & Co is a professional accounting and consultancy firm
                    dedicated to providing high-quality financial services to clients
                    in the business market. With a specific focus on accounting and
                    finance, the firm specializes in assisting its clients in managing
                    and understanding the financial aspects of their business.
                  </p>
                  <p>
                    To achieve this goal, Asah Alpha & Co has formed a specialized team
                    of accounting professionals, financial consultants, and experts in
                    other finance-related fields. These qualified and experienced
                    professionals use their knowledge and expertise to offer personalized
                    solutions for each client.
                  </p>
                  <p>
                    Asah Alpha & Co starts its work by understanding the client's needs.
                    To fully comprehend a client's financial situation, the firm conducts
                    a thorough analysis of the business's financial data. This analysis
                    helps identify challenges and opportunities for financial growth.
                  </p>
                  <p>
                    Based on the analysis results, Asah Alpha & Co provides specialized
                    advice and consultancy to improve the financial management of the
                    business. This includes developing appropriate financial strategies,
                    expense control, tax optimization, and preparing regular and reliable
                    financial reports.
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="left-image">
                  <img src="/images/about-image.jpg" alt="" />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  </div>

  <div className="fun-facts">
    <Container>
      <Row>
        <Col md={6}>
          <div className="left-content">
            <span>Interesting Facts</span>
            <h2>
              Our services for the successful growth of{" "}
              <em>your business</em>
            </h2>
            <p>
              We have enjoyed a rich journey of successes and significant
              achievements in the fields of accounting and financial consultancy.
              Our clients testify to the excellent results we have achieved for them.
            </p>
            <p>
              We have enjoyed a rich journey of successes and significant
              achievements in the fields of accounting and financial consultancy.
              Our clients testify to the excellent results we have achieved for them.
            </p>
          </div>
        </Col>
        <Col md={6} className="align-self-center">
          <Row>
            <Col md={6}>
              <div className="count-area-content">
                <div className="count-digit">15,000</div>
                <div className="count-title">Work Hours</div>
              </div>
            </Col>
            <Col md={6}>
              <div className="count-area-content">
                <div className="count-digit">1000+</div>
                <div className="count-title">Positive Reviews</div>
              </div>
            </Col>
            <Col md={6}>
              <div className="count-area-content">
                <div className="count-digit">500</div>
                <div className="count-title">Projects</div>
              </div>
            </Col>
            <Col md={6}>
              <div className="count-area-content">
                <div className="count-digit">30</div>
                <div className="count-title">Certifications</div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </div>
 
  <div className="testimonials" style={{paddingTop:"100px",paddingBottom:"150px"}}>
    <Container>
      <Row>
        <Col md={12}>
          <div className="section-heading">
            <h2>
              What <em>they say</em> about us
            </h2>
            <span>testimonials from our esteemed clients</span>
          </div>
        </Col>
        <Col md={6}>
          <div className="testimonial-item">
            <div className="inner-content">
              <h4> <em>SONARA</em></h4>
              <p>
              "Exceptional service! Our accounting needs were met with precision and professionalism. Asah Alpha & Co truly delivers excellence."
              </p>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="testimonial-item">
            <div className="inner-content">
              <h4><em>MTN</em></h4>
              <p>
              "Grateful for their financial insights. Asah Alpha & Co's team navigated complexities seamlessly, ensuring our fiscal success. Highly recommend!"
              </p>
            </div>
          </div>
        </Col>

      </Row>
      <br />
      <Row>
        <Col md={6}>
          <div className="testimonial-item">
            <div className="inner-content">
              <h4> <em>Tradex</em></h4>
              <p>
              "Reliable and insightful! Asah Alpha & Co's accounting expertise transformed our financial strategies, boosting efficiency and profitability. Impressive service!"
              </p>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="testimonial-item">
            <div className="inner-content">
              <h4><em>Feicom</em></h4>
              <p>
              "Outstanding support! Asah Alpha & Co's team consistently goes above and beyond, providing strategic financial guidance that has profoundly impacted our business."
              </p>
            </div>
          </div>
        </Col>

      </Row>
      <br />
      <Row>
        <Col md={6}>
          <div className="testimonial-item">
            <div className="inner-content">
              <h4> <em>SINOPEC</em></h4>
              <p>
              "Efficient and trustworthy accounting services. Asah Alpha & Co has been an invaluable partner, offering tailored solutions that fit our unique business requirements."
              </p>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="testimonial-item">
            <div className="inner-content">
              <h4><em>Setoa Italia</em></h4>
              <p>
              "Top-notch professionals! Asah Alpha & Co's dedicated team not only manages our accounts flawlessly but also offers valuable advice for sustained financial growth."
              </p>
            </div>
          </div>
        </Col>

      </Row>
      
    </Container>
  </div>
</div>
  );
}

export default LayoutWrapper(About);
