import React from 'react'
import { Col } from 'react-bootstrap';

const ClientSlider = (props) => {
    const {name, img_url} = props.item;
  return (
        <Col md={4}>
          <div className="test-item" style={{alignItems:"center",justifyContent:"center"}}>
            <img src={img_url} alt={name} />
            
          </div>
        </Col>
  )
}

export default ClientSlider
