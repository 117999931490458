import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div>
  <footer>
    <Container>
      <Row>
        <Col md={5} className="footer-item">
        <img src="asah.jpeg" alt="Asah Alpha & Co" style={{color:"white",paddingBottom:"15px"}}></img>
          <p>
          We are one of the leading African companies in its field, covering all markets while implementing different accounting systems, without any language, cultural or even professional barriers.
          </p>
          <div className="social-icons">
            <li>
              <a target="_blank" href="mailto:info@asahalpha.com" title="Mail Us">
                <i className="fa fa-envelope"></i>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://twitter.com/asahalpha_info" title="Twitter">
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li>
              <a target="_blank" href="" title="Linkedin">
                <i className="fa fa-linkedin"></i>
              </a>
            </li>
          </div>
        </Col>
        <Col md={4} className="footer-item">
          <h4>Our Useful Links</h4>
          <ul className="menu-list">
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
          </ul>
        </Col>
        <Col md={3} className="footer-item">
          <h4>Our Support</h4>
          <ul className="menu-list">
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="mailto:info@asahalpha.com">Email</Link>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  </footer>

  <div className="sub-footer">
    <Container>
      <Row>
        <Col md={12}>
          <p>Copyright &copy; 2024 Asah Alpha & Co</p>
        </Col>
      </Row>
    </Container>
  </div>
</div>

  );
}

export default Footer;
