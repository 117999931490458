import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import LayoutWrapper from "../Layout";
import RequestForm from "../Components/request-form";
import { Link } from "react-router-dom";

function Request() {
  return (
    <div>
  <div className="page-heading header-text">
    <Container>
      <Row>
        <Col md={12}>
          <h1>Request For Proposal</h1>
          <span>
          How can we help your business?
          </span>
        </Col>
      </Row>
    </Container>
  </div>
  <div className="callback-form">
    <Container>
      <Row>
        <Col md={12}>
          <div className="section-heading">
          <h2>
            Thank you for your interest in <em>Asah Alpha & Co's</em> services
            </h2>
            <span>Please take a few moments to complete this form.</span>
            <span>
            Fields marked with an asterisk <em>(*)</em> are required.
            </span>
          </div>
        </Col>
        <Col md={12}>
          <RequestForm />
        </Col>
      </Row>
    </Container>
  </div>
  <div>
    <br />
    <br />
  </div>
  <div>
    <br />
    <br />
  </div>
  <div>
    <br />
    <br />
  </div>
</div>

  );
}

export default LayoutWrapper(Request);
