import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LayoutWrapper from '../Layout';
import ContactForm from '../Components/contact-form';
import Clients from '../Components/clients';
import HeaderSlider from '../Components/header-slider';

function Home() {
  return (
    <div>
  <div className="main-banner header-text" id="top">
    <HeaderSlider />
  </div>

  <div className="request-form">
    <Container>
      <Row>
        <Col md={8}>
          <h4>Contact us</h4>
          <span>We are ready to assist you now.</span>
        </Col>
        <Col md={4}>
          <Link to="/contact" className="border-button">
            Contact
          </Link>
        </Col>
      </Row>
    </Container>
  </div>

  <div className="more-info">
    <Container>
      <Row>
        <Col md={6}>
          <div className="left-image">
            <img src="who.jpeg" alt="" />
          </div>
        </Col>
        <Col md={6} className="align-self-center">
          <div className="right-content">
            <span>Who we are</span>
            <h2>
              Learn more about <em>Asah Alpha & Co</em>
            </h2>
            <p>
              Asah Alpha & Co is a professional accounting and consultancy firm,
              specialized in providing high-quality financial services to
              clients in the business market. Through a specialized team of
              accounting professionals, financial consultants, and experts in
              other finance-related fields, Asah Alpha & Co offers personalized
              solutions to meet the unique needs of each client.
            </p>
            <Link to="/about" className="filled-button">
              Learn More
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
  <div className="services">
    <Container>
      <Row>
        <Col md={12}>
          <div className="section-heading">
            <h2>
              Our <em>Services</em>
            </h2>
            <span>We offer various services</span>
          </div>
        </Col>
        <Col md={4}>
          <div className="service-item">
            <img src="ac-1.jpg" alt="" />
            <div className="down-content">
              <h4>Core Services</h4>
              <p>
                Asah Alpha & Co offers comprehensive accounting, auditing and taxation services that
                include the process of identifying, recording, and evaluating
                financial transactions.
              </p>
              <Link to="/services" className="filled-button">
                Read More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div className="service-item">
            <img src="images/service_02.jpg" alt="" />
            <div className="down-content">
              <h4>Support Services</h4>
              <p>
              Asah Alpha & Co excels in IT solutions, legal practices, finance, and management consultancy, providing expertise in various domains for businesses.
              </p>
              <Link to="/services" className="filled-button">
                Read More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div className="service-item">
            <img src="hr-1.avif" alt="" />
            <div className="down-content">
              <h4>Human Resource Solution</h4>
              <p>
              Asah Alpha & Co's HR Division provides rigorous, standardized training for optimal human capital performance in specialized business disciplines.
              </p>
              <Link to="/services" className="filled-button">
                Read More
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
  <Clients />
  
  <div className="fun-facts" style={{marginTop:"60px",paddingTop:"80px"}}>
    <Container>
      <Row>
        <Col md={6}>
          <div className="left-content">
            <span>Interesting Facts</span>
            <h2>
              Our services for the successful growth of{" "}
              <em>your business</em>
            </h2>
            <p>
              We have enjoyed a rich journey of successes and significant
              achievements in the fields of accounting and financial consultancy.
              Our clients testify to the excellent results we have achieved for them.
            </p>
            <p>
              We have enjoyed a rich journey of successes and significant
              achievements in the fields of accounting and financial consultancy.
              Our clients testify to the excellent results we have achieved for them.
            </p>
          </div>
        </Col>
        <Col md={6} className="align-self-center">
          <Row>
            <Col md={6}>
              <div className="count-area-content">
                <div className="count-digit">15,000</div>
                <div className="count-title">Work Hours</div>
              </div>
            </Col>
            <Col md={6}>
              <div className="count-area-content">
                <div className="count-digit">1000+</div>
                <div className="count-title">Positive Reviews</div>
              </div>
            </Col>
            <Col md={6}>
              <div className="count-area-content">
                <div className="count-digit">500</div>
                <div className="count-title">Projects</div>
              </div>
            </Col>
            <Col md={6}>
              <div className="count-area-content">
                <div className="count-digit">30</div>
                <div className="count-title">Certifications</div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </div>
  <div className="callback-form">
    <Container>
      <Row>
        <Col md={12}>
          <div className="section-heading">
            <h2>
              Contact us <em>now</em>
            </h2>
            <span>
              We are ready to listen and provide the right help
              for you
            </span>
          </div>
        </Col>
        <Col md={12}>
          <ContactForm />
        </Col>
      </Row>
    </Container>
  </div>
  <div>
    <br />
    <br />
  </div>
  <div>
    <br />
    <br />
  </div>
  <div>
    <br />
    <br />
  </div>
</div>
  );
}

export default LayoutWrapper(Home);